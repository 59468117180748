import cn from 'classnames';
import { VFC } from 'react';
import { Image } from '../Image';
import CustomSpinner from './custom-spinner.gif';
import styles from './full-screen-loader.module.scss';

export type ScreenLoaderProps = {
  /**
   * When to show Spinner. (default: true)
   */
  loading?: boolean;
  /**
   * Text to show when loading
   */
  loadingText?: React.ReactNode;
  /**
   * Size of loading icon.
   */
  size?: number;
  /**
 * ClassName for styling.
 */
  className?: string;
};

const ScreenLoader: VFC<ScreenLoaderProps> = ({
  size = 40,
  loading = true,
  loadingText = 'Loading...',
  className,
}) => {
  if (loading === false) {
    return null;
  }

  return (
    <div className={cn(styles.screenLoader, className)}>
      <Image
        src={CustomSpinner}
        height={size}
        width={size}
        alt="loading..."
      />
      <div style={{ marginTop: 8 }}>
        {loadingText}
      </div>
    </div>
  );
};

export default ScreenLoader;
