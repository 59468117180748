import React, { FC, createElement } from 'react';

interface Props {
  src: string | { src: string };
  height?: string | number;
  width?: string | number;
  alt?: string | number;
}

const Image: FC<Props> = ({ src, ...props }) => createElement('img', { src, ...props });
// try {
//   return createElement(require.resolve('next/image').default, {
//     src: typeof src === 'string' ? src : src.src,
//     ...props,
//   });
// } catch {
// }

export default Image;
