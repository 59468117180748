import { FullScreenLoader } from 'sailor-ui/FullScreenLoader';
import { useAuth0 } from '@auth0/auth0-react';
import MetaData from '@components/MetaData';
import { MetaDataInterface } from '@interfaces/metaData';
import Head from 'next/head';
import React, { useEffect } from 'react';

const LoginPage: React.FC = () => {
  const meta: MetaDataInterface = {
    title: 'Redirect | TheBoatDB',
  };

  const { buildAuthorizeUrl } = useAuth0();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      buildAuthorizeUrl().then((loginUrl) => {
        window.location.href = loginUrl;
      });
    }
  }, [buildAuthorizeUrl]);

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <MetaData {...meta} />
      <FullScreenLoader loadingText="Redirecting..." />
      <p>Redirecting...</p>
    </div>
  );
};

export default LoginPage;
